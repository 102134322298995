$footerColor: #252627;
$buttonColor: #44C45F;
$errorColor: #e32929;
$greyColor: #404444;
$darkBlueColor: #5F6B81;

@mixin bradius($content: 3px) {
    border-radius: $content;
    -webkit-border-radius: $content;
    -moz-border-radius: $content;
}

@mixin boxsize($content:border-box) {
    box-sizing: $content;
    -o-box-sizing: $content;
    -ms-box-sizing: $content;
    -moz-box-sizing: $content;
    -khtml-box-sizing: $content;
    -webkit-box-sizing: $content
}

@mixin transition($content:0.3s ease all) {
    transition: $content;
    -o-transition: $content;
    -moz-transition: $content;
    -webkit-transition: $content;
    -ms-transition: $content;
}

@mixin shadowbox($content) {
    -webkit-box-shadow: $content;
    -moz-box-shadow: $content;
    box-shadow: $content;
}