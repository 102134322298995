@import 'layouts/_mixins.scss';
@import 'list.scss';

.home-banner {
    width:100%;
    background: url('../images/home-banner.jpg') 50%;
    background-size:cover;
    height:900px;
    line-height:900px;
    text-align:center;

    .home-banner_info {
        text-align:center;
        color:#FFFFFF;
        display:inline-block;
        line-height:normal;
        vertical-align:middle;

        .home-banner_info__title {
            line-height:70px;
            font-size:64px;
            font-weight:500;
            font-family:Roboto, sans-serif;
            display:inline-block;
            margin-bottom:34px;
        }
        .home-banner_info__sub {
            font-size:28px;
            line-height:36px;
            margin-bottom:50%;
        }
        .home-banner_info__button {
            display:inline-block;

            a {
                color:#FFFFFF;
            }
        }
    }
}

.box-list_title {
    font-size:42px;
    line-height:52px;
    color:$greyColor;
    font-family:"Roboto", sans-serif;
    font-weight:500;
    margin-bottom:40px;
    display:inline-block;
    text-align:center;
    max-width:700px;

    span {
        margin-top:25px;
        font-size:20px;
        line-height:30px;
        font-family:"Open Sans", sans-serif;
        font-weight:normal;
        color:#5F6B81;
        display:block;
    }
}

.services-list {
    padding:120px 0 80px;
    background:#F5F5F5;
    width:100%;
    text-align:center;

    ul {
        padding:0;
        display:flex;
        margin:0 -15px 70px;

        li {
            flex-grow:1;
            list-style-type:none;
            padding:0 15px;

            a {
                text-decoration:none;
            }

            .services-list_img {
                -webkit-border-top-left-radius: 4px;
                -webkit-border-top-right-radius: 4px;
                -moz-border-radius-topleft: 4px;
                -moz-border-radius-topright: 4px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                width:100%;
                margin-bottom:24px;

                img {
                    width:100%;
                    height:auto;
                }
            }
            .services-list_name {
                font-size:24px;
                line-height:28px;
                color:$greyColor;
                font-family:"Roboto", sans-serif;
                margin-bottom:12px;
            }
            .services-list_price {
                font-size:16px;
                line-height:24px;
                color:#5F6B81;
            }
        }
    }
}

.home-wedoing {
    padding: 120px 0;
    background:#FFFFFF;
    text-align:center;
    font-size:0;

    .home-wedoing_element {
        width:25%;
        margin-bottom:50px;
        display:inline-block;
        padding:70px 30px 0 0;
        vertical-align:top;
        position:relative;

        .home-wedoing_element__title {
            font-size:24px;
            line-height:28px;
            font-family:"Roboto", sans-serif;
            color:#3D3D3D;
            margin-bottom:15px;

            &.img1, &.img2, &.img3, &.img4 {
                &::before {
                    content: '';
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &.img1::before {
                background:url('../images/shield_icon.svg') 0 0 no-repeat;
            }
            &.img2:before {
                background:url('../images/feather_icon.svg') 0 0 no-repeat;
            }
            &.img3:before {
                background:url('../images/bell_icon.svg') 0 0 no-repeat;
            }
            &.img4:before {
                background:url('../images/download_icon.svg') 0 0 no-repeat;
            }
        }
        span {
            font-size:16px;
            line-height:24px;
            color:#5F6B81;
        }
    }
    .home-wedoing_photo {
        float:right;
        width:50%;
        height:430px;
        background: url("../images/camera.jpg");
        background-size:cover;
    }
    .home-wedoing_photo__left {
        float:left;
        width:50%;
        height:430px;
        background: url("../images/camera.jpg");
        background-size:cover;
        margin:0 20px 20px 0;
    }
    .home-wedoing_floatright {
        display:inline;
        font-family:"Roboto", sans-serif;
        color:$darkBlueColor;
        font-size:16px;
        line-height:26px;

        p {
            margin:0;
        }
    }
    ul {
        padding:0 0 0 20px;
        margin:30px 0 0;

        li {
            list-style-type:none;
            font-size:18px;
            line-height:26px;
            color:$darkBlueColor;
            margin-bottom:12px;

            span {
                color:$greyColor;
                font-weight:500;
            }
            &::before {
                content: "\2022";
                color: #0FACFC;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }
    }
}
.portfolio-list {
    padding:120px 0 80px;
    background:#F5F5F5;
    width:100%;
    text-align:center;

    .portfolio-list_main {
        font-size:0;
        margin-bottom:130px;

        .portfolio-list_main__box {
            width:50%;
            display:inline-block;
            vertical-align:top;
            font-size:16px;
            line-height:24px;
            color: $darkBlueColor;
            text-align:left;

            &:first-child {
                padding-right:15px;
                position:relative;
                top:-10px;
            }
            &:last-child {
                padding-left:15px;
            }
            .portfolio-list_main__title {
                font-size:36px;
                line-height:48px;
                font-family:"Roboto", sans-serif;
                font-weight:500;
                color:$greyColor;
                margin-bottom:10px;
            }
            img {
                width:100%;
                height:auto;
            }
        }
        .button {
            margin-top:15px;
        }
    }
}

.we-created {
    background:#FFFFFF;
    padding:120px 0;
    text-align:center;

    .container {
        text-align:left;
    }

    .we-created_note {
        font-size:24px;
        line-height:28px;
        font-family:"Roboto", sans-serif;
        color:#3D3D3D;
        margin-bottom:22px;
    }
    .we-created_title {
        font-size:42px;
        line-height:52px;
        color:$greyColor;
        font-family:"Roboto", sans-serif;
        font-weight:500;
        max-width:800px;
        margin-bottom:60px;
    }

    ul {
        margin:0;
        padding:60px 0 0;
        font-size:0;
        border-top:1px solid #D8D8D8;

        li {
            list-style-type:none;
            display:inline-block;
            vertical-align:top;
            width:25%;
            font-size:16px;
            line-height:24px;
            color:$darkBlueColor;
            padding-top:60px;
            position:relative;

            span {
                font-size:24px;
                line-height:28px;
                color:$greyColor;
                margin-bottom:12px;
            }

            &::before {
                position:absolute;
                left:0;
                content:'';
                top:0;
                width:100%;
                height:50px;
            }
            &.img1::before {
                background:url('../images/folder_icon.svg') 0 50% no-repeat;
            }
            &.img2::before {
                background:url('../images/calendar_icon.svg') 0 50% no-repeat;
            }
            &.img3::before {
                background:url('../images/git_commit_icon.svg') 0 50% no-repeat;
            }
            &.img4::before {
                background:url('../images/slack_icon.svg') 0 50% no-repeat;
            }
        }
    }
}