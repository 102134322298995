@import 'layouts/_mixins.scss';

.filters {
    margin:70px 0 44px;

    ul {
        padding:0;
        margin:0;
        font-size:0;

        li {
            list-style-type:none;
            display:inline-block;
            vertical-align:middle;
            font-size:16px;
            line-height:40px;
            height:40px;
            padding:0 16px;
            border:1px solid #D8D8D8;
            @include bradius(4px);
            margin-right:16px;
            margin-bottom:16px;
            font-family:"Roboto",sans-serif;
            background:#FFFFFF;
            cursor:pointer;

            &:hover, &.active {
                background: $buttonColor;
                color:#FFFFFF;

                a {
                    color:#FFFFFF;
                }
            }

            a {
                color:$greyColor;
            }
        }
    }
}

ul.list {
    padding:0;
    margin:0 -15px;
    text-align:left;
    font-size:0;
    margin-top:70px;

    li {
        list-style-type:none;
        width:33.3333%;
        padding:0 15px;
        display:inline-block;
        vertical-align:top;
        font-size:16px;
        line-height:24px;
        margin-bottom:100px;

        a {
            text-decoration:none;
            color:$darkBlueColor;
        }

        .image {
            width:100%;
            height:auto;
            overflow:hidden;
            margin-bottom:30px;
            position:relative;

            img {
                width:100%;
            }
            &:after {
                content:'';
                background:url('../images/play.svg');
                position:absolute;
                width:68px;
                height:68px;
                left:50%;
                margin-left:-34px;
                top: 50%;
                margin-top: -34px;
            }

            &.without_play:after {
                display:none;
            }
        }
        .title {
            font-size:24px;
            line-height:28px;
            font-family:"Roboto", sans-serif;
            margin-bottom:10px;
            color:$greyColor;
        }
        .min_price {
            color: $greyColor;
            font-size: 14px;
            font-family: "Roboto", sans-serif;
            font-weight: 500;

            &.price {
                font-size:16px;
            }
        }
    }
}
.filters+.list {
    margin-top:0;
}